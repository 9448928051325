<template>
  <div class="customer-list">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap">
      <div class="text-h5 font-weight-bold">Customers</div>
      <v-spacer></v-spacer>
      <v-card color="accent" flat tile class="ms-sm-3 width100" max-width="480">
        <v-text-field
          class="field46"
          v-model="search"
          placeholder="Search for name, email, phone number..."
          prepend-inner-icon="mdi-magnify"
          :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch"
          hide-details
          outlined
          dense
          required
          color="primary"
          height="46"
          @change="(page = 1), getData()"
        ></v-text-field>
      </v-card>
    </div>
    <div class="mt-4">
      <v-card tile flat color="transparent" v-if="!data.result" class="d-flex justify-center align-center" :height="`calc(100vh - 240px)`">
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data.result"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 240px)`"
          class="d-sm-block d-none accent"
        >
          <template v-slot:header.name>
            Full Name
            <v-btn
              @click="sortItems('full_name')"
              icon
              class="btn-sort"
              :class="sort.some((i) => i == 'full_name_asc' || i == 'full_name_desc') ? 'primary white--text' : ''"
            >
              <v-icon>{{ sort.some((i) => (i == 'full_name_asc' ? true : false)) ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin' }}</v-icon>
            </v-btn>
          </template>
          <template v-slot:header.email>
            Email
            <v-btn
              @click="sortItems('email')"
              icon
              class="btn-sort"
              :class="sort.some((i) => i == 'email_asc' || i == 'email_desc') ? 'primary white--text' : ''"
            >
              <v-icon>{{ sort.some((i) => (i == 'email_asc' ? true : false)) ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin' }}</v-icon>
            </v-btn>
          </template>
          <template v-slot:header.phone_number>
            Phone number
            <v-btn
              @click="sortItems('phone')"
              icon
              class="btn-sort"
              :class="sort.some((i) => i == 'phone_asc' || i == 'phone_desc') ? 'primary white--text' : ''"
            >
              <v-icon>{{ sort.some((i) => (i == 'phone_asc' ? true : false)) ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin' }}</v-icon>
            </v-btn>
          </template>
          <template v-slot:header.registered_at>
            Registered At
            <v-btn
              @click="sortItems('registered')"
              icon
              class="btn-sort"
              :class="sort.some((i) => i == 'registered_asc' || i == 'registered_desc') ? 'primary white--text' : ''"
            >
              <v-icon>{{ sort.some((i) => (i == 'registered_asc' ? true : false)) ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin' }}</v-icon>
            </v-btn>
          </template>
          <template v-if="!data.pages" v-slot:no-data>
            <v-card tile flat color="transparent" class="d-flex justify-center align-center">
              <div>
                <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
                <div class="text-center text--text mt-4">Sorry, but nothing found</div>
              </div>
            </v-card>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="link" @click="openCustomer(item.id)">
                <td :class="item.account_removed ? 'error--text' : ''">
                  {{ item.first_name }} {{ item.last_name }} <span v-if="item.account_removed">(deleted)</span>
                </td>
                <td :class="item.account_removed ? 'error--text' : ''">{{ item.email }}</td>
                <td :class="item.account_removed ? 'error--text' : ''">{{ item.phone_number }}</td>
                <td :class="item.account_removed ? 'error--text' : ''">
                  {{
                    item.registered_at
                      ? new Date(item.registered_at).toLocaleString('en-GB', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })
                      : ''
                  }}
                </td>
              </tr>
            </tbody>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }} {{ data.total > 1 ? 'persons' : 'person' }}</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template>
        </v-data-table>
        <div class="d-block d-sm-none">
          <div v-for="item in data.result" :key="item.id" @click="openCustomer(item.id)" class="px-4 pt-2">
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Full Name</div>
              <div :class="item.account_removed ? 'error--text' : ''" class="font-weight-medium f18">
                {{ item.first_name }} {{ item.last_name }} <span v-if="item.account_removed">(deleted)</span>
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Email</div>
              <div :class="item.account_removed ? 'error--text' : ''" class="font-weight-medium f18 text-truncate">{{ item.email }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Phone number</div>
              <div :class="item.account_removed ? 'error--text' : ''" class="font-weight-medium f18 text-capitalize">
                +{{ item.phone_number }}
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Registered At</div>
              <div :class="item.account_removed ? 'error--text' : ''" class="font-weight-medium f18">
                {{
                  item.registered_at
                    ? new Date(item.registered_at).toLocaleString('en-GB', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                    : ''
                }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }} {{ data.total > 1 ? 'persons' : 'person' }}</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      search: this.$route.query.src || '',
      page: Number(this.$route.query.page) || 1,
      sort: this.$route.query.sort ? this.$route.query.sort.split(',') : [],
      headers: [
        { text: 'Full Name', value: 'name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Phone number', value: 'phone_number', sortable: false },
        { text: 'Registered At', value: 'registered_at', sortable: false },
      ],
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'sort') {
        this.$notify({
          title: 'Warning',
          message: 'Sorry, sort list invalid',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openCustomer(id) {
      this.$router.push(`/customers/${id}`);
    },
    sortItems(c) {
      if (this.sort.some((i) => i == `${c}_desc`)) {
        const idx = this.sort.indexOf(`${c}_desc`);
        this.sort.splice(idx, 1);
      } else if (this.sort.some((i) => i == `${c}_asc`)) {
        const idx = this.sort.indexOf(`${c}_asc`);
        this.sort.splice(idx, 1);
        this.sort.push(`${c}_desc`);
      } else {
        this.sort.push(`${c}_asc`);
      }
      this.$nextTick(() => {
        this.getData();
      });
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getCustomerList', {
          search: this.search,
          page: this.page,
          sort: this.sort,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'sort_list__invalid') {
        this.notifi('sort');
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.customerList;
    },
  },
  destroyed() {
    this.$store.dispatch('setCustomerList', {});
  },
};
</script>

<style lang="scss">
.customer-list {
  .btn-sort {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .v-icon {
      font-size: 16px;
    }
  }
  table tbody tr:hover td:first-of-type {
    color: var(--v-primary-base) !important;
  }
}
</style>
